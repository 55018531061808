import * as THREE from '../build/three.module.js';
import { Utils } from '../utils/Utils.js';
import Navigator from './Navigator.js';

export default class Step extends THREE.Group {

    constructor() {

        super();

    }

    on(){


    }

    onComplete(){

    }

    off(){
        //console.log("off");

    }

    offComplete(){

       //console.log("offComplete");
        this.clear();
        Navigator.INSTANCE.events.onStepOffComplete.dispatch();
    }

    update(){

    }

    clear(){

    }




}

export { Step };
