import * as THREE from '../js/build/three.module.js';
import { OrbitControls } from '../js/lib/jsm/controls/OrbitControls.js';
import { VRButton } from '../js/lib/jsm/webxr/VRButton.js';
import { XRControllerModelFactory } from '../js/lib/jsm/webxr/XRControllerModelFactory.js';

import Button from '../js/Button.js';

import Navigator from './navigator/Navigator.js';
import { Loader }from '../js/utils/Loader.js';
import { Utils } from '../js/utils/Utils.js';


export default class App  {


    constructor(data, params) {
        this.data = data;
        this.params = params;


        App.INSTANCE = this;
        App.DEBUG = params.debug ? params.debug : false;
        App.debugRay = params.debugRay ? params.debugRay : false;
        App.debugRoomRotation = params.debugRoomRotation ? params.debugRoomRotation : false;

        App.firstVisitId = params.firstVisitId ? params.firstVisitId : 0;
        //----------------Path--------------------------------
        App.videosPath = 'videos/';
        App.imagesPath = 'images/';

        //----------------Data--------------------------------

        this.currentLanguage = 'fr';
        this.currentQuality = 'high';

        this.stepsData = data.steps;
        this.stepsCount = this.stepsData.length;


        this.currentOverObject = null;
        this.intersected = [];
        this.interactiveObjects =[];
        this.mouse = new THREE.Vector2();

        this.isVR = Utils.detectVRCompatible();

        this.prevGamePads = new Map();
        this.speedFactor = [0.1, 0.1, 0.1, 0.1];



        //----------------Objects--------------------------------
        this.container;
        this.renderer;
        this.camera;
        this.scene;

        this.controller1
        this.controller2;
        this.mainController;
        this.controllerGrip1;
        this.controllerGrip2;

        this.raycaster = new THREE.Raycaster();
        this.tempMatrix = new THREE.Matrix4();

        this.controls;





        //----------------Handlers--------------------------------
        this.renderHandler = this.render.bind(this);
        this.onClickHandler = this.onClick.bind(this);
        this.onMouseMoveHandler = this.onMouseMove.bind(this);
        this.onKeyUpHandler = this.onKeyUp.bind(this);
        //----------------Events--------------------------------
        this.events = {};

        this.events.onEnableControls = new signals.Signal();
        this.events.onEnableControls.add(this.onEnableControls, this);
        this.events.onDisableControls = new signals.Signal();
        this.events.onDisableControls.add(this.onDisableControls, this);
        this.events.onCenterControls = new signals.Signal();
        this.events.onCenterControls.add(this.onCenterControls, this);



        //----------------Loading--------------------------------
        this.loader = new Loader();
        this.loader.add('homeBackground',  App.imagesPath + "home.png");
        this.loader.add('homeTitle', App.imagesPath + "title.png");
        this.loader.add('fr_over',  App.imagesPath + "button_fr_over.png");
        this.loader.add('fr_out',  App.imagesPath + "button_fr_out.png");
        this.loader.add('en_over',  App.imagesPath + "button_en_over.png");
        this.loader.add('en_out',  App.imagesPath + "button_en_out.png");
        this.loader.add('low_over', App.imagesPath + "button_low_over.png");
        this.loader.add('low_out',  App.imagesPath + "button_low_out.png");
        this.loader.add('high_over', App.imagesPath + "button_high_over.png");
        this.loader.add('high_out',  App.imagesPath + "button_high_out.png");

        this.loader.add('start_over_fr',  App.imagesPath + "button_start_over_fr.png");
        this.loader.add('start_out_fr',  App.imagesPath + "button_start_out_fr.png");
        this.loader.add('start_over_en',  App.imagesPath + "button_start_over_en.png");
        this.loader.add('start_out_en',  App.imagesPath + "button_start_out_en.png");

        this.loader.add('continue_over_fr',  App.imagesPath + "button_continue_over_fr.png");
        this.loader.add('continue_out_fr',  App.imagesPath + "button_continue_out_fr.png");
        this.loader.add('continue_over_en',  App.imagesPath + "button_continue_over_en.png");
        this.loader.add('continue_out_en',  App.imagesPath + "button_continue_out_en.png");

        this.loader.add('launch_over_fr',  App.imagesPath + "button_launch_over_fr.png");
        this.loader.add('launch_out_fr',  App.imagesPath + "button_launch_out_fr.png");
        this.loader.add('launch_over_en',  App.imagesPath + "button_launch_over_en.png");
        this.loader.add('launch_out_en',  App.imagesPath + "button_launch_out_en.png");

        this.loader.add('enter_over_fr',  App.imagesPath + "button_enter_over_fr.png");
        this.loader.add('enter_out_fr',  App.imagesPath + "button_enter_out_fr.png");
        this.loader.add('enter_over_en',  App.imagesPath + "button_enter_over_en.png");
        this.loader.add('enter_out_en',  App.imagesPath + "button_enter_out_en.png");

        this.loader.add('visit_over_fr',  App.imagesPath + "button_visit_over_fr.png");
        this.loader.add('visit_out_fr',  App.imagesPath + "button_visit_out_fr.png");
        this.loader.add('visit_over_en',  App.imagesPath + "button_visit_over_en.png");
        this.loader.add('visit_out_en',  App.imagesPath + "button_visit_out_en.png");

        this.loader.add('warning_en',  App.imagesPath + "warning_en.png");
        this.loader.add('warning_fr',  App.imagesPath + "warning_fr.png");

        this.loader.add('circle',  App.imagesPath + "circle.png");
        //this.loader.add('start_over_en',  "images/button_start_over_en.png");

        this.loader.load(this.onLoaded.bind(this));


    }

    onLoaded(){
        this.init();
        this.initControllers();
        this.navigator.start();
    }


    init(){

        this.container = document.getElementById( 'container' );
        this.container.addEventListener( 'pointerup', this.onClickHandler);
        this.container.addEventListener( 'mousemove', this.onMouseMoveHandler);
        document.body.addEventListener( 'keyup', this.onKeyUpHandler);

        this.camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.1, 2000 );
        this.camera.layers.enable(1);
        this.camera.rotation.order = 'YXZ'



        this.camera.lookAt(new THREE.Vector3(0,0,-500));

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0x131313 );
        //this.scene.background = new THREE.Color( 0x000000 );

        this.scene.add( new THREE.HemisphereLight( 0x808080, 0x606060 ) );
        const light = new THREE.DirectionalLight( 0xffffff );
        light.position.set( 0, 6, 0 );
        this.scene.add( light );

        //this.videoPlayer = new VideoPlayer(this.scene);



        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.renderer.xr.enabled = true;
        this.renderer.xr.setReferenceSpaceType( 'local' );
        container.appendChild( this.renderer.domElement );

        if(this.isVR ||App.DEBUG){
            this.VRButton = VRButton.createButton( this.renderer );
            document.body.appendChild( this.VRButton);
        }

        if(App.debugRay){
            this.debugButton = new Button( 'debug', 40, 'circle', false, null);
            //this.debugButton = new Button( 'debug', 100, 'continue_over_fr', false, null);

            this.debugButton.position.set(0, 0, -200);
            this.scene.add(this.debugButton);
            this.debugButton.renderOrder = 10;

        }


        window.addEventListener( 'resize', this.onWindowResize.bind(this), false );
        this.animate();

        this.controls = new OrbitControls( this.camera, this.container );

        this.controls.target.set( 0, 0, 0 );
        //this.controls.enabled = false;
        this.controls.enableRotate = false;
        this.controls.rotateSpeed = -0.3;
        this.controls.enableDamping = true;
        //this.controls.update();

        this.camera.position.set( 0, 0, 1 );
        this.camera.rotation.set (0.1,0,0);

        this.navigator = new Navigator(this.stepsData,this.scene,this.params.step);

    }

    initHome() {
        this.home = new Home();
        this.home.position.set(0,0, -100);
        this.scene.add(this.home);
        this.isHome = true;

    }

    initNext() {
        this.next = new Button( 'next', 50, 'next', false, this.onNext.bind(this));
        this.next.position.set(0,0, -90);
        this.scene.add(this.next);
        this.next.hide();
    }







    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    //--------------------------  EVENTS ---------------------------------------
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------

    animate() {

        this.renderer.setAnimationLoop( this.renderHandler);
    }

    render() {

        this.cleanIntersected();

        this.renderer.render(this.scene, this.camera );
    }



    onStart(){//IMPORTANT
        this.container.removeEventListener( 'pointerup', this.onClickHandler);
        this.container.addEventListener( 'pointerdown', this.onClickHandler);
 

    }

    onDisableControls(){
        if(!this.renderer.xr.isPresenting){
            //this.controls.enabled = false;
            this.controls.clear();
            this.controls.enableRotate = false;
            this.camera.lookAt(new THREE.Vector3(0,0,-500));
            //this.controls.update();
        }
    }

    onEnableControls(){
        if(!this.renderer.xr.isPresenting){
            //this.controls.enabled = true;
            this.controls.enableRotate = true;
            //this.camera.position.set( 0, 0, 1 );
            //this.camera.lookAt(new THREE.Vector3(0,0,-500));
            this.controls.update();
        }
    }

    onCenterControls(){
        //this.controls.enabled = false;
        this.camera.position.set( 0, 0, 1 );
        this.camera.rotation.set (0.1,0,0);
    }




    onKeyUp(e){
        switch(e.keyCode){
            case 39 :
                if(App.DEBUG){
                    this.nextDebug();
                }
                else{
                    this.manualNext();
                }


                /* if(this.visit && App.debugRoomRotation){
                    this.visit.turnRight();
                }
                else{

                } */
            break;
            case 37 :
                this.manualPrevious();
               /*  if(this.visit && App.debugRoomRotation){
                    this.visit.turnLeft();
                }
                else{

                } */
            break;
        }
    }

    manualNext(){
        this.navigator.onNextStep();
    }

    manualPrevious(){
        this.navigator.onPreviousStep();
    }

    nextDebug(){
        this.navigator.nextDebug();
    }



    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    //--------------------------  INTERACTION ----------------------------------
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------


    onClick( event ) {

        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    	this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        this.raycaster.setFromCamera( this.mouse, this.camera );
        const intersections = this.raycaster.intersectObjects( this.interactiveObjects.filter(object => object.visible));
        const object = this.getFirstIntersection(intersections);
        if(object){
            object.button.select();
        }

    }

    onMouseMove(event){
        if(this.renderer.xr.isPresenting){
            return;
        }
        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        this.raycaster.setFromCamera( this.mouse, this.camera );
        //console.log(this.interactiveObjects);
        const intersections = this.raycaster.intersectObjects( this.interactiveObjects.filter(object => object.visible));
        this.checkOver(intersections,true);

        if(App.debugRay){
            this.updateDebugRay();

        }

    }

    checkOver(intersections, isMainController){
        const object = this.getFirstIntersection(intersections);

        if(object){
            if(isMainController){
                if(this.currentOverObject == null){
                    this.currentOverObject = object;
                    this.currentOverObject.button.over();
                }
                else if(this.currentOverObject != object){
                    this.currentOverObject.button.out();
                    this.currentOverObject = object;
                    this.currentOverObject.button.over();
                }
            }

            return object;
        }
        else if(this.currentOverObject){
            if(isMainController){
                this.currentOverObject.button.out();
                this.currentOverObject = null;
            }

            return null;
        }
    }

    getFirstIntersection(intersections){
        if ( intersections.length > 0 ) {
            const intersection = intersections[ 0 ];
            //console.log(intersection.distance);
            const object = intersection.object;
            return object;
        }
        return null;
    }


    onSelectStart( event ) {
        //console.log("selectStart");
        const controller = event.target;
        this.mainController = controller;
        const intersections = this.getVRIntersections( controller );
        const object = this.getFirstIntersection(intersections);
        if(object){
            object.button.select();
            controller.userData.selected = object;
        }
    }
    onSelectEnd( event ) {

    }

    onControllerMove(controller){

        const intersections = this.getVRIntersections( controller );
        const obj = this.checkOver(intersections, this.mainController == controller);
        //const line = controller.getObjectByName( 'line' );
        const reticle = controller.getObjectByName( 'reticle' );
        if(obj){

            const target1 = new THREE.Vector3();
            const target2 = new THREE.Vector3();
            obj.getWorldPosition(target1);
            controller.getWorldPosition(target2);
            //line.scale.z =  target1.distanceTo(target2);
            reticle.visible = true;
            reticle.position.set(0, 0, -target1.distanceTo(target2) + 10);
        }
        else{
            reticle.visible = false;
            //line.scale.z = 5;
            //reticle.visible = false;
        }

    }

    getVRIntersections( controller ) {

        this.tempMatrix.identity().extractRotation( controller.matrixWorld );

        this.raycaster.ray.origin.setFromMatrixPosition( controller.matrixWorld );
        this.raycaster.ray.direction.set( 0, 0, - 1 ).applyMatrix4( this.tempMatrix );

        return this.raycaster.intersectObjects( this.interactiveObjects.filter(object => object.visible));

    }

    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    //--------------------------  CONTOLLERS -----------------------------------
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------

    initControllers() {
        this.controller1 = this.renderer.xr.getController( 0 );
        this.controller1.addEventListener( 'selectstart', this.onSelectStart.bind(this));
        this.controller1.addEventListener( 'selectend', this.onSelectEnd.bind(this));
        this.scene.add( this.controller1 );

        this.controller2 = this.renderer.xr.getController( 1 );
        this.controller2.addEventListener( 'selectstart', this.onSelectStart.bind(this));
        this.controller2.addEventListener( 'selectend', this.onSelectEnd.bind(this));
        this.scene.add( this.controller2 );

        if(this.controller2){
            this.mainController = this.controller2;
        }
        else{
            this.mainController = this.controller1;
        }

        const controllerModelFactory = new XRControllerModelFactory();

        this.controllerGrip1 = this.renderer.xr.getControllerGrip( 0 );
        this.controllerGrip1 .add( controllerModelFactory.createControllerModel( this.controllerGrip1 ) );
        this.scene.add( this.controllerGrip1  );

        this.controllerGrip2 = this.renderer.xr.getControllerGrip( 1 );
        this.controllerGrip2.add( controllerModelFactory.createControllerModel( this.controllerGrip2 ) );
        this.scene.add( this.controllerGrip2);


        const geometry = new THREE.BufferGeometry().setFromPoints( [ new THREE.Vector3( 0, 0, 0 ), new THREE.Vector3( 0, 0, - 1 ) ] );
        const line = new THREE.Line( geometry );
        line.name = 'line';
        line.scale.z = 1;
        this.controller1.add( line.clone() );
        this.controller2.add( line.clone() );

        const map = new THREE.TextureLoader().load( App.imagesPath + "reticle.png" );
        const material = new THREE.SpriteMaterial( { map: map, transparent : true, depthWrite : false} );

        const sprite = new THREE.Sprite( material );
        sprite.name = 'reticle';
        sprite.scale.set(3,3,3);
        sprite.position.set(-20,0, -95);
        sprite.renderOrder = -5;
        //this.scene.add( sprite );

        const reticle1 =  sprite.clone();
        reticle1.renderOrder = 100000;
        const reticle2 =  sprite.clone();
        reticle2.renderOrder = 100001;
        this.controller1.add(reticle1);
        this.controller2.add(reticle2);
    }

    checkController() {
        var handedness = "unknown";
    
        //determine if we are in an xr session
        const session = this.renderer.xr.getSession();
        let i = 0;
    
        if (session) {
            let xrCamera = this.renderer.xr.getCamera(camera);
            xrCamera.getWorldDirection(cameraVector);
    
            //a check to prevent console errors if only one input source
            if (this.isIterable(session.inputSources)) {
                for (const source of session.inputSources) {
                    if (source && source.handedness) {
                        handedness = source.handedness; //left or right controllers
                    }
                    if (!source.gamepad) continue;
                    const controller = this.renderer.xr.getController(i++);
                    const old = this.prevGamePads.get(source);
                    const data = {
                        handedness: handedness,
                        buttons: source.gamepad.buttons.map((b) => b.value),
                        axes: source.gamepad.axes.slice(0)
                    };
                    if (old) {
                        data.buttons.forEach((value, i) => {
                            //handlers for buttons
                            if (value !== old.buttons[i] || Math.abs(value) > 0.8) {
                                //check if it is 'all the way pushed'
                                if (value === 1) {
                                    console.log("Button" + i + "Down");
                                    if (data.handedness == "left") {
                                        console.log("Left Paddle Down");

                                        if (i == 1) {
                                            //dolly.rotateY(-THREE.Math.degToRad(1));
                                        }
                                        if (i == 3) {
                                            //reset teleport to home position
                                            /* dolly.position.x = 0;
                                            dolly.position.y = 5;
                                            dolly.position.z = 0; */
                                        }
                                    } else {
                                        console.log("Right Paddle Down");
                                        if (i == 1) {
                                            /*dolly.rotateY(THREE.Math.degToRad(1));*/
                                        }
                                    }
                                } else {
                                    console.log("Button" + i + "Up");
                                    this.manualNext();
                                    if (i == 1) {
                                        //use the paddle buttons to rotate
                                        if (data.handedness == "left") {
                                            console.log("Left Paddle Down");
                                            //dolly.rotateY(-THREE.Math.degToRad(Math.abs(value)));
                                        } else {
                                            console.log("Right Paddle Down");
                                            //dolly.rotateY(THREE.Math.degToRad(Math.abs(value)));
                                        }
                                    }
                                }
                            }
                        });
                        data.axes.forEach((value, i) => {
                            //handlers for thumbsticks
                            //if thumbstick axis has moved beyond the minimum threshold from center, windows mixed reality seems to wander up to about .17 with no input
                            if (Math.abs(value) > 0.2) {
                                //set the speedFactor per axis, with acceleration when holding above threshold, up to a max speed
                                this.speedFactor[i] > 1 ? (this.speedFactor[i] = 1) : (this.speedFactor[i] *= 1.001);
                                console.log(value, this.speedFactor[i], i);
                                if (i == 2) {
                                    //left and right axis on thumbsticks
                                    if (data.handedness == "left") {
                                        (data.axes[2] > 0) ? console.log('left on left thumbstick') : console.log('right on left thumbstick')
                                        (data.axes[2] > 0) ? this.manualNext() : this.previousNext();
                                        
                                        //move our dolly
                                        //we reverse the vectors 90degrees so we can do straffing side to side movement
                                        //dolly.position.x -= cameraVector.z * this.speedFactor[i] * data.axes[2];
                                        //dolly.position.z += cameraVector.x * this.speedFactor[i] * data.axes[2];
    
                                        //provide haptic feedback if available in browser
                                        if (
                                            source.gamepad.hapticActuators &&
                                            source.gamepad.hapticActuators[0]
                                        ) {
                                            var pulseStrength = Math.abs(data.axes[2]) + Math.abs(data.axes[3]);
                                            if (pulseStrength > 0.75) {
                                                pulseStrength = 0.75;
                                            }
    
                                            var didPulse = source.gamepad.hapticActuators[0].pulse(
                                                pulseStrength,
                                                100
                                            );
                                        }
                                    } else {
                                        (data.axes[2] > 0) ? console.log('left on right thumbstick') : console.log('right on right thumbstick')
                                        (data.axes[2] > 0) ? this.manualNext() : this.previousNext();
                                        //dolly.rotateY(-THREE.Math.degToRad(data.axes[2]));
                                    }
                                    controls.update();
                                }
    
                                if (i == 3) {
                                    //up and down axis on thumbsticks
                                    if (data.handedness == "left") {
                                        (data.axes[3] > 0) ? console.log('up on left thumbstick') : console.log('down on left thumbstick')
                                        //dolly.position.y -= this.speedFactor[i] * data.axes[3];
                                        //provide haptic feedback if available in browser
                                        if (
                                            source.gamepad.hapticActuators &&
                                            source.gamepad.hapticActuators[0]
                                        ) {
                                            var pulseStrength = Math.abs(data.axes[3]);
                                            if (pulseStrength > 0.75) {
                                                pulseStrength = 0.75;
                                            }
                                            var didPulse = source.gamepad.hapticActuators[0].pulse(
                                                pulseStrength,
                                                100
                                            );
                                        }
                                    } else {
                                        (data.axes[3] > 0) ? console.log('up on right thumbstick') : console.log('down on right thumbstick')
                                        //dolly.position.x -= cameraVector.x * this.speedFactor[i] * data.axes[3];
                                        //dolly.position.z -= cameraVector.z * this.speedFactor[i] * data.axes[3];
    
                                        //provide haptic feedback if available in browser
                                        if (
                                            source.gamepad.hapticActuators &&
                                            source.gamepad.hapticActuators[0]
                                        ) {
                                            var pulseStrength = Math.abs(data.axes[2]) + Math.abs(data.axes[3]);
                                            if (pulseStrength > 0.75) {
                                                pulseStrength = 0.75;
                                            }
                                            var didPulse = source.gamepad.hapticActuators[0].pulse(
                                                pulseStrength,
                                                100
                                            );
                                        }
                                    }
                                    controls.update();
                                }
                            } else {
                                //axis below threshold - reset the speedFactor if it is greater than zero  or 0.025 but below our threshold
                                if (Math.abs(value) > 0.025) {
                                    this.speedFactor[i] = 0.025;
                                }
                            }
                        });
                    }
                    this.prevGamePads.set(source, data);
                }
            }
        }
    }

  isIterable(obj) {
	// checks for null and undefined
	if (obj == null) {
		return false;
	}
	return typeof obj[Symbol.iterator] === "function";
}



    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    //--------------------------  INTERSECT -----------------------------------
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------

    cleanIntersected() {
        while ( this.intersected.length ) {

            const object = this.intersected.pop();
            object.material.emissive.r = 0;

        }
    }

    getLocalizedImge(string) {
        return string + "_" + this.currentLanguage;
    }

    isInteractive(object) {
        this.interactiveObjects.push(object);
    }

    removeFromInteractive(object) {
        if(this.interactiveObjects.indexOf(object) > -1){
            this.interactiveObjects.splice(this.interactiveObjects.indexOf(object),1);
        }
    }


    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    //--------------------------  RESIZE -----------------------------------
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------

    onWindowResize() {

        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize( window.innerWidth, window.innerHeight );

    }

    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    //--------------------------  RENDER -----------------------------------
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------

    animate() {

        this.renderer.setAnimationLoop( this.renderHandler);
    }

    render() {
        this.navigator.update();
        if(this.renderer.xr.isPresenting){
            //this.checkController();
            this.onControllerMove( this.controller1 );
            this.onControllerMove( this.controller2 );
            gsap.ticker.tick();
        }
        else{
            this.controls.update();
        }



        this.cleanIntersected();

        this.renderer.render(this.scene, this.camera );

    }

    updateDebugRay(){

        let cameraForward = new THREE.Vector3();
        this.camera.getWorldDirection( cameraForward );
        cameraForward.multiplyScalar(200);
        this.debugButton.position.set(cameraForward.x, cameraForward.y,cameraForward.z);
        this.debugButton.lookAt(this.camera.position.x,this.camera.position.y,this.camera.position.z);
        this.debugButton.position.set(Math.round(this.debugButton.position.x), Math.round(this.debugButton.position.y), Math.round(this.debugButton.position.z))
       console.log(this.debugButton.position);


    }
}
