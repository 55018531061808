import 'regenerator-runtime/runtime'
import App  from './js/App.js';
import { Navigator } from './js/navigator/Navigator.js';


let data = {
  
  steps : [
    {
      type : "home",
      next : 1,

    },
    {
      type : "video",
      file: "Intro Partie 1",
      end : "pause",
      buttons : [{ buttonSize : 100, buttonPosition : {x : -8, y : -19, z : -198},localizable : true, buttonId : "enter_over", target : 2}],
      next : 2,
    },
    {
      type : "video",
      file: "Intro Partie 2",
      end : "pause",
      buttons : [{ buttonSize : 100, buttonPosition : {x : -1, y : -24, z : -198},localizable : true, buttonId : "continue_over", target : 3}],
      next : 3
    },
    {
      type : "video",
      file: "Spot 2",
      end : "pause",
      buttons : [{ buttonSize : 100, buttonPosition : {x : 55, y : -31, z : -189},localizable : true, buttonId : "continue_over", target : 4}],
      next : 4
    },
    {
      type : "video",
      file: "Spot 3",
      end : "pause",
      buttons : [{ buttonSize : 80, buttonPosition : {x : -108, y : -118, z : -118}, localizable : false, buttonId : "circle", target : 5}],
      next : 5
    },
    {
      type : "video",
      file: "Spot 3 Animation Reacteur",
      end : "pause",
      rotation : -2.2,
      buttons : [{ buttonSize : 100, buttonPosition : {x: -36, y: -19, z: -196},localizable : true, buttonId : "continue_over", target : 6}],
      next : 6
    },
    {
      type : "video",
      file: "Spot 4",
      end : "pause",
      buttonSize : 130,
      buttons : [
        { buttonSize : 130, buttonPosition : {x: 86, y: -22, z: 180}, localizable : true, buttonId : "launch_over", target : 7},
        { buttonSize : 100, buttonPosition : {x : 148, y : -28, z : -128}, localizable : true, buttonId : "enter_over", target : 8},
      ],
      next : 8,      
    },
    {
      type : "video",
      file: "Spot 4 Slide Digital Twin",
      end : "pause",
      buttons : [{ buttonSize : 130, buttonPosition : {x: 86, y: -22, z: 180},localizable : true, buttonId : "enter_over", target : 8}],
      next : 8,    
    },
    {
      type : "video",
      file: "Spot 5",
      end : "pause",
      buttons : [{ buttonSize : 130, buttonPosition : {x : -72, y : -35, z : -183},localizable : true, buttonId : "enter_over", target : 9}],
      next : 9,  
    },
    
    {
      type : "video",
      file: "Conclusion",
      end : "pause",
      buttons : [{ buttonSize : 130, buttonPosition :{x : -4, y : -27, z : -198},localizable : true, buttonId : "visit_over", target : 10}],
      next : 10,  
    },
    {
      type : "visit",
      next : 0,
      rooms : [
        {
          id : "Centre 1a",
          rotation: Math.PI,
          buttons : [
            {position :  {x : 170, y : -23, z : -101}, id : "Spot 4 - Devant"},
            {position :  {x: 163, y: -23, z: 108}, id : "Centre 1c"},
            {position :  {x: -47, y: -23, z: -193}, id : "Centre 1b"},
            {position :  {x: -187, y: -23, z: -70}, id : "Spot 3"},
            {position :  {x: -191, y: -24, z: 54}, id : "Centre 2"}
          ]
         },
         {
           id : "Spot 4 - Devant",
           rotation:-0.3,
           buttons : [
             {position :  {x : -135, y : -40, z : -141}, id : "Spot 4 - Derriere"},
             {position :  {x : 197, y : -10, z : 32}, id : "Centre 1b"},
           ]
          },
          {
           id : "Spot 4 - Derriere",
           rotation:2.7,
           buttons : [
             {position :  {x : -130, y : -29, z : 155}, id : "Spot 4 - Devant"},
             //{position :  {x : 100, y : -44, z : 157}, id : "room2"},
           ]
          },
          {
            id : "Centre 1b",
            rotation:2.8,
            buttons : [
              {position :  {x : 197, y : -17, z : 28}, id : "Spot 4 - Devant"},
              {position :  {x : -182, y : -15, z : 80}, id : "Spot 3"},
              {position :  {x: 31, y: -22, z: 196}, id : "Centre 1a"},
            ]
           },
          {
            id : "Spot 3",
            rotation:-2.3,
            buttons : [
              {position :  {x :189, y : -33, z : 54}, id : "Centre 1b"},
              {position :   {x :-84, y : -13, z : 180}, id : "Centre 2"},
              {position :   {x: 57, y: -33, z: 186}, id : "Centre 1a"},
              
            ]
           },
           {
            id : "Centre 2",
            rotation:-0.9,
            buttons : [
              {position :  {x: 141, y: -26, z: 139}, id : "Spot 3"},
              {position :  {x : -163, y: 14, z: -114}, id : "Spot 2"},
              {position :  {x: 52, y: -26, z: 191}, id : "Centre 1a"}
            ]
           },
           {
            id : "Spot 2",
            rotation:0,
            buttons : [
              {position :  {x: -199, y: -3, z: 20}, id : "Spot X"},
              {position :  {x: -95, y: -20, z: 175}, id : "Centre 2"},
            ]
           },
           {
            id : "Spot X",
            rotation:2.2,
            buttons : [
              {position :  {x: -121, y: -2, z: 159}, id : "Spot 2"},
              {position :  {x: -174, y: -49, z: -86}, id : "Centre 1c"},
            ]
           },
           {
            id : "Centre 1c",
            rotation:-1.3,
            buttons : [
              {position :  {x: 132, y: -85, z: -124}, id : "Centre 1a"},
              {position :  {x: -194, y: -19, z: -44}, id : "Spot X"},
            ]
           },
      ]
    }
   ],
}
const params = {};
const url = new URL(window.location.href);
let searchParams = new URLSearchParams(url.search);
searchParams.forEach(function(value, key) {
     if(value == "false"){
         value = false;
     }
     else if(value == "true"){
         value = true;
     }
     else if (!isNaN(Number(value)) ){
    	 value = Number(value);
     }
    params[key] = value;
});



let app = new App(data,params);




