import * as THREE from '../js/build/three.module.js';
import Image from '../js/utils/Image.js';
import Button from '../js/Button.js';
import App from '../js/App.js';
import { Utils } from '../js/utils/Utils.js';
import Step from './navigator/Step.js';
import Navigator from './navigator/Navigator.js';

export default class Home extends Step {

    constructor() {
        super();

        this.width = 100;
        this.height = 100;


        this.init();
        this.initState();
    }

    init(){


        this.background = new Image( this.width,  "homeBackground", -1);
        this.background.position.set(0,0,0);
        this.add(this.background);


        this.title = new Image( 50,  "homeTitle",0);
        this.title.position.set(0,38.5,1);
        this.add(this.title);

        const radioButtonsWidth = 22;
        const radioButtonsX = 12;
        const languagesButtonsY = 12;
        this.frenchButton = new Button( 'fr', radioButtonsWidth, 'fr_over', true, this.onClickLanguage.bind(this));
        this.frenchButton.isRadio = true;
        this.frenchButton.position.set(-radioButtonsX,languagesButtonsY,1);
        this.add(this.frenchButton);
        this.englishButton = new Button( 'en', radioButtonsWidth,'en_over', true, this.onClickLanguage.bind(this));
        this.englishButton.isRadio = true;
        this.englishButton.position.set(radioButtonsX,languagesButtonsY,1);
        this.add(this.englishButton);

        this.lowButton = new Button( 'low', radioButtonsWidth, 'low_over', true, this.onClickQuality.bind(this));
        this.lowButton.position.set(-radioButtonsX,0,1);
        this.lowButton.isRadio = true;
        this.add(this.lowButton);
        this.highButton = new Button( 'high', radioButtonsWidth,'high_over', true, this.onClickQuality.bind(this));
        this.highButton.isRadio = true;
        this.highButton.position.set(radioButtonsX,0,1);
        this.add(this.highButton);

        this.startButtonFR = new Button( 'start', 55, 'start_over_fr', true, this.onStart.bind(this));
        this.startButtonFR.position.set(0,-20,1);
        this.add(this.startButtonFR);
        this.startButtonEN = new Button( 'start', 55, 'start_over_en', true, this.onStart.bind(this));
        this.startButtonEN.position.set(0,-20,1);
        this.add(this.startButtonEN);

        if(App.INSTANCE.isVR){
            this.warningEN = new Image( 65,  "warning_fr", 0);
            this.warningEN.position.set(0,23,1);
            this.add(this.warningEN);
            this.warningFR = new Image( 65,  "warning_en", 0);
            this.warningFR.position.set(0,22.5,1);
            this.add(this.warningFR);
        }

        App.INSTANCE.events.onDisableControls.dispatch();
        App.INSTANCE.events.onCenterControls.dispatch();
    }

    initState(){

        //console.log(App.INSTANCE.currentLanguage);
        if(App.INSTANCE.currentLanguage == 'fr'){
            this.frenchButton.active();
            this.englishButton.deactive();
            this.startButtonFR.show();
            this.startButtonEN.hide();
            this.add(this.warningFR);
            this.remove(this.warningEN);
        }
        else{
            this.englishButton.active();
            this.frenchButton.deactive();
            this.startButtonEN.show();
            this.startButtonFR.hide();
            this.remove(this.warningFR);
            this.add(this.warningEN);
        }

        if(App.INSTANCE.currentQuality == 'low'){
            this.lowButton.active();
            this.highButton.deactive();
        }
        else{
            this.lowButton.deactive();
            this.highButton.active();
        }
    }

    select(){
        this.clickHandler();
    }

    onStart(){
        Navigator.INSTANCE.events.onNextStep.dispatch();
    }

    onClickLanguage(id){
        App.INSTANCE.currentLanguage = id;
        this.initState();
    }

    onClickQuality(id){
        App.INSTANCE.currentQuality = id;
        this.initState();
        Navigator.INSTANCE.events.onCheckPreload.dispatch();
    }

    on(){
        Utils.visibleRecursive(this, true);
        
    }

    off(){
        super.off();
        Utils.visibleRecursive(this, false);
        this.offComplete();
    }

    offComplete(){
        super.offComplete();    
    }

    clear(){
        this.frenchButton.destroy();
        this.englishButton.destroy();
        this.lowButton.destroy();
        this.highButton.destroy();
        this.startButtonFR.destroy();
        this.startButtonEN.destroy();
        
    }
}

